<template>
    <div class="card">
        <div class="card-header">Удалить {{item.type.sign > 0 ? 'надбавку' : 'штраф'}} ?</div>
        <div class="card-body">
            <p>Вы действительно хотите удалить {{item.type.sign > 0 ? 'надбавку' : 'штраф'}} {{item.type.name}} на пользователя {{item.user.fullname}}?</p>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-6 text-left">
                    <button :disabled="loading" @click="$emit('close')" class="btn btn-success">Отмена</button>
                </div>
                <div class="col-6 text-right">
                    <button @click="deleteItem()" :disabled="loading" class="btn btn-danger">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SalaryBountyMixin} from '@mixins';

    export default {
        name: "DeleteForm",
        mixins: [SalaryBountyMixin]
    }
</script>

<style scoped>

</style>
