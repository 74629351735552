<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-4">
                    <button @click="create()" class="btn btn-success"><i class="fas fa-plus"></i> Добавить</button>
                </div>
                <div class="col-3">
                    <date-time-picker
                        label="С"
                        locale="ru"
                        id="date-from"
                        :format="null"
                        input-size="sm"
                        position="bottom"
                        :no-label="true"
                        :no-header="true"
                        :no-button="true"
                        :no-clear-button="true"
                        :auto-close="true"
                        :only-date="true"
                        :disabled="loading"
                        v-model="filters.dateFrom"
                        formatted="DD.MM.YYYY"
                        :max-date="filters.dateTo ? moment(filters.dateTo).format('YYYY-MM-DD') : null"
                    />
                </div>
                <div class="col-3">
                    <date-time-picker
                        label="По"
                        locale="ru"
                        id="'date-to"
                        :format="null"
                        input-size="sm"
                        position="bottom"
                        :no-label="true"
                        :no-header="true"
                        :no-button="true"
                        :no-clear-button="true"
                        :auto-close="true"
                        :only-date="true"
                        :disabled="loading"
                        v-model="filters.dateTo"
                        formatted="DD.MM.YYYY"
                        :min-date="filters.dateFrom ? moment(filters.dateFrom).format('YYYY-MM-DD') : null"
                    />
                </div>
                <div class="col-2">
                    <select v-model="filters.sign" :disabled="loading" class="form-control">
                        <option :value="0">Выберите тип</option>
                        <option :value="1">Надбавка</option>
                        <option :value="-1">Штраф</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-sm table-bordered mb-0 table-striped">
                    <thead class="thead-dark">
                    <tr>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Дата, смена</th>
                        <th>Тип</th>
                        <th>Название</th>
                        <th class="text-right">Сумма</th>
                        <th>Начислено</th>
                        <th>Начислил</th>
                        <th>Комментарий</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loading">
                        <td colspan="10">
                            <div class="alert alert-info text-center mb-0"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                        </td>
                    </tr>
                    <tr v-else-if="items.length === 0">
                        <td colspan="10">
                            <div class="alert alert-warning text-center mb-0">Пусто</div>
                        </td>
                    </tr>
                    <template v-else>
                        <tr v-for="(item, index) in items">
                            <td>{{item.user.fullname}}</td>
                            <td>
                                <template v-if="item.workPosition">
                                    {{item.workPosition.name }}
                                </template>
                                <div v-else class="text-center">---</div>
                            </td>
                            <td>{{item.actualShift.name}}</td>
                            <td>{{item.type.sign_name}}</td>
                            <td>{{item.type.name}}</td>
                            <td class="text-right">{{item.amount}}</td>
                            <td>{{moment(item.created_at).format('DD.MM.YYYY HH:mm')}}</td>
                            <td>{{item.creator.fullname}}</td>
                            <td>{{item.comment}}</td>
                            <td class="text-right">
                                <button v-if="$auth.check([ROLE_DIRECTOR])" @click="update(item, index)" class="btn btn-sm"><i class="fas fa-edit"></i> изменить</button>
                                <button v-if="$auth.check([ROLE_DIRECTOR])" @click="deleteItem(item, index)" class="btn btn-sm btn-danger"><i class="fas fa-times"></i> удалить</button>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <b-row>
                <b-col cols="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
                </b-col>
                <b-col cols="6" class="d-flex justify-content-end">
                    <b-pagination
                        v-model="meta.current_page"
                        :total-rows="meta.total_rows"
                        :per-page="meta.per_page"
                        @input="load"
                    />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import DateTimePicker from "vue-ctk-date-time-picker"
    import lsfMixin from "../../../utils/localStorageFilterMixin";
    import CreateEditForm from "./CreateEditForm";
    import DeleteForm from "./DeleteForm";
    import {SALARY_ENDPOINT} from "../../../utils/endpoints";
    import {ROLE_DIRECTOR} from "../../../utils/Roles";
    import {sortBy} from 'lodash';

    const WITH = [
        'type',
        'user',
        'creator',
        'actualShift',
        'workPosition'
    ];

    const APPENDS = [
        'current_work_position'
    ];

    export default {
        name: "SalaryBounty",
        mixins: [lsfMixin],
        components: {DateTimePicker},
        watch: {
            filters: {
                handler: function () {
                    this.load();
                },
                deep: true
            },
        },
        data() {
            return {
                ROLE_DIRECTOR,
                loading: false,
                filters: {
                    dateFrom: moment().startOf('month'),
                    dateTo: moment(),
                    sign: 0,
                },
                items: [],
                meta: {
                    current_page: 1,
                    per_page: 25,
                    total_rows: 0,
                },
                modalParams: {
                    adaptive: true,
                    height: 'auto',
                    width: 900,
                    classes: 'overflowable'
                }
            }
        },
        methods: {
            moment,
            create() {
                this.$modal.show(CreateEditForm, {
                    successCallback: (item) => {
                        this.items.unshift(item);
                        this.$forceUpdate();
                    },
                    with: WITH,
                    appends: APPENDS,
                }, this.modalParams);
            },
            update(item, index) {
                this.$modal.show(CreateEditForm, {
                    index: index,
                    value: item,
                    successCallback: (item) => {
                        this.items[index] = item;
                        this.$forceUpdate();
                    },
                    with: WITH,
                    appends: APPENDS,
                }, this.modalParams)
            },
            deleteItem(item, index) {
                this.$modal.show(DeleteForm, {
                    index: index,
                    value: item,
                    successCallback: (item) => {
                        this.items.splice(index, 1);
                        this.$forceUpdate();
                    },
                    with: WITH,
                    appends: APPENDS,
                }, this.modalParams)
            },
            load() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.$http.get(SALARY_ENDPOINT + '/bounty', {
                    params: {
                        with: WITH,
                        page: this.meta.current_page,
                        filters: {
                            sign: this.filters.sign,
                            dateFrom: moment(this.filters.dateFrom).format('YYYY-MM-DD'),
                            dateTo: moment(this.filters.dateTo).format('YYYY-MM-DD')
                        },

                        without_loading: true,
                    }
                }).then(response => {
                    this.items = response.data.data.data;
                    this.items = sortBy(this.items, obj => obj.user.fullname)
                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                }).catch(response => {
                    let message = 'Ошибка';
                    if (!!response.data && !!response.data.message) {
                        message = response.data.message;
                    }
                    this.$toast.error(message);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
