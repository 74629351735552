<template>
    <select v-model="id" class="form-control" @change="onChange">
        <option :disabled="!nullable" :value="null">Выберите тип надбавки/штрафа</option>
        <option v-for="option in options" :value="option.id">
            {{option.name}}
        </option>
    </select>
</template>

<script>
    import {SALARY_ENDPOINT} from "../../../../utils/endpoints";

    export default {
        name: "SalaryBountyTypeInput",
        props: {
            nullable: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        watch: {
            value() {
                this.id = this.value ? this.value.id : null;
            },
        },
        data() {
            return {
                id: null,
                options: []
            };
        },
        methods: {
            onChange() {
                this.$emit('input', this.options.find(i => i.id === this.id));
            },
            load() {
                this.$http.get(SALARY_ENDPOINT + '/bounty/type', {
                    params: {
                        limit: 0,
                        without_loading: true
                    }
                }).then(response => {
                    this.options = response.data.data.data;
                }).catch(response => this.$root.responseError(response, 'Ошибка загрузки типов надбавок/штрафов'));
            },
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
