import { render, staticRenderFns } from "./SalaryBountyTypeInput.vue?vue&type=template&id=d8b48d84&scoped=true&"
import script from "./SalaryBountyTypeInput.vue?vue&type=script&lang=js&"
export * from "./SalaryBountyTypeInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8b48d84",
  null
  
)

export default component.exports