<template>
    <div class="card">
        <div class="card-header">
            {{!!this.item.id ? 'Изменить' : 'Создать'}}
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <b-form-group>
                        <user-input v-model="item.user"></user-input>
                    </b-form-group>
                    <error-list v-if="!!errors.user_id" :errors="errors.user_id" :message-function="errorMessageFormatter"></error-list>
                </div>
                <div class="col-6">
                    <actual-shift-picker v-model="item.actual_shift" :allowEmptyShift="true" />
                    <error-list v-if="!!errors.actual_shift_id" :errors="errors.actual_shift_id" :message-function="errorMessageFormatter"></error-list>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <b-form-group description="Тип надбавки/штрафа">
                        <salary-bounty-type-input v-model="item.type"></salary-bounty-type-input>
                    </b-form-group>
                    <error-list v-if="!!errors.salary_bounty_type_id" :errors="errors.salary_bounty_type_id" :message-function="errorMessageFormatter"></error-list>
                </div>
                <div class="col-2">
                    <b-form-group description="Сумма">
                        <b-form-input class="text-right" :disabled="!(!!item.type) || !item.type.dynamic_amount" type="number" :formatter="numberFormatter" v-model="item.amount"></b-form-input>
                    </b-form-group>
                    <error-list v-if="!!errors.amount" :errors="errors.amount" :message-function="errorMessageFormatter"></error-list>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form-group description="Комментарий">
                        <textarea v-model="item.comment" class="form-control"></textarea>
                    </b-form-group>
                    <error-list v-if="!!errors.comment" :errors="errors.comment" :message-function="errorMessageFormatter"></error-list>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-6 text-left">
                    <button :disabled="loading" @click="$emit('close')" class="btn btn-danger">Отмена</button>
                </div>
                <div class="col-6 text-right">
                    <button :disabled="loading" @click="confirm() && save()" class="btn btn-success">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SalaryBountyMixin} from '@mixins';
    import UserInput from "../../_common/UserInput";
    import ActualShiftPicker from "../../_common/ActualShiftPicker";
    import SalaryBountyTypeInput from "../../_common/Salary/Bounty/SalaryBountyTypeInput";
    import ErrorList from "../../_common/ErrorList";

    export default {
        name: "CreateEditForm",
        mixins: [SalaryBountyMixin],
        components: {UserInput, ActualShiftPicker, ErrorList, SalaryBountyTypeInput},
        methods: {
            confirm() {
                return window.confirm('Сохранить?');
            }
        }
    }
</script>

<style scoped>

</style>
